<template>
  <main class="dashboard links">
    <div class="mb-4">
      <h2>{{ name }}</h2>
      <BackButton></BackButton>
    </div>
    <div class="filters mb-5">
      <form action="" @submit.prevent="getData">
        <div class="d-flex filters">
          <MultiSelect v-if="active_country.code"
                       v-model="active_country"
                       :options="project.countries"
                       :multi="false"
                       :required="true"
                       name="Страна"></MultiSelect>

          <date-picker v-model="filter.date"
                       locale="ru"
                       :clearable="false"
                       format="dd.MM.yyyy"
                       model-type="yyyy-MM-dd"
                       auto-apply
                       :allowed-dates="allowedDates"
                       :enable-time-picker="false">
          </date-picker>
          <MultiSelect v-if="filter_tones.length"
                       v-model="filter.tone"
                       :options="filter_tones"
                       :multi="true"
                       :only_value="true"
                       name="Тональность"></MultiSelect>

          <MultiSelect v-if="filter_type_tones.length"
                       v-model="filter.type_tone"
                       :options="filter_type_tones"
                       :multi="true"
                       :only_value="true"
                       name="Тип ссылки"></MultiSelect>

          <MultiSelect v-if="filter_types.length"
                       v-model="filter.kind_type"
                       :options="filter_types"
                       :multi="true"
                       :only_value="true"
                       name="Вид разметки"></MultiSelect>

          <MultiSelect v-if="keywords.length"
                       v-model="filter.keyword"
                       :options="keywords"
                       :multi="false"
                       :only_value="true"
                       name="Запрос"></MultiSelect>

          <MultiSelect
              v-model="filter.delete_type"
              :options="$DELETE_TYPES"
              :multi="false"
              :only_value="true"
              name="Метод воздействия"></MultiSelect>

          <MultiSelect
              v-model="filter.court"
              :options="$COURT_TYPE"
              :multi="false"
              :only_value="true"
              name="Уходит по суду"></MultiSelect>

          <MultiSelect
              v-model="filter.state"
              :options="$STATES"
              :multi="false"
              :only_value="true"
              name="ТОП"></MultiSelect>

          <MultiSelect
              v-model="filter.trust"
              :options="trust"
              :multi="false"
              :only_value="true"
              name="Тип источника"></MultiSelect>

          <MultiSelect v-if="themes.length"
                       v-model="filter.theme"
                       :options="themes"
                       :multi="false"
                       :only_value="true"
                       field_name="theme"
                       name="Темы"></MultiSelect>
          <MultiSelect v-if="phrases.length"
                       v-model="filter.phrase"
                       :options="phrases"
                       :multi="false"
                       :only_value="true"
                       field_name="text"
                       name="Негативные фразы"></MultiSelect>


          <div role="search">
            <i class="bi bi-search"></i>
            <input type="search" class="form-control" placeholder="Поиск по ссылке" v-model="q" aria-label="Поиск">
          </div>

          <div class="form-check form-switch ms-1 pt-2">
            <input class="form-check-input" v-model="filter.uniq" type="checkbox" role="switch" id="uniq_link">
            <label class="form-check-label" for="uniq_link">Уникальные</label>
          </div>

          <div class="form-check form-switch ms-1 pt-2">
            <input class="form-check-input" v-model="filter.no_check" type="checkbox" role="switch" id="uniq_link">
            <label class="form-check-label" for="uniq_link">Не проверены</label>
          </div>

          <div class="per-page">
            <label>Показать по</label>
            <select name="" class="select form-control" id="" v-model="size">
              <option v-for="per_page in per_pages" :value="per_page.id" :key="per_page.name">{{ per_page.name }}
              </option>
            </select>
          </div>

          <button class="btn btn-sm btn-outline-dark max-content" type="submit">
            <i class="bi bi-search"></i>Показать
          </button>

        </div>
        <div class="row">
          <div class="phrases col-5 mt-2">
            <div><a href="" @click.prevent="$func.showElement('phrase_link')"
                    style="border-bottom: 1px dotted #ccc">Негативных фраз: <b>{{ phrases.length }}</b></a>
              <button class=" btn btn-sm text-success"
                      @click="phrases.push({id: null, text: '', edit: true});$func.showElement('phrase_link', true);"
                      type="button" title="Добавить фразу"><i class="bi bi-plus-square"></i></button>
              <button class=" btn btn-sm text-success"
                      @click="searchPhrase"
                      type="button" title="Запустить поиск"><i class="bi bi-search"></i></button>
            </div>


            <div class="list hide" id="phrase_link">
              <template v-for="phrase in phrases">
                <div v-if="phrase.edit">
                  <input v-model="phrase.text" class="form-control">
                  <button class="btn btn-sm btn-success text-white" type="button" @click="addPhrase(phrase)"><i
                      class="bi bi-check2"></i></button>
                  <button class="btn btn-sm btn-danger text-white" type="button" @click="phrase.edit=false;"><i
                      class="bi bi-x-lg"></i></button>
                </div>
                <div v-else>
                  <span>{{ phrase.text }}</span>
                  <button class="btn btn-sm btn-success text-white" type="button" @click="phrase.edit = true;"><i
                      class="bi bi-pencil"></i></button>
                  <button class="btn btn-sm btn-danger text-white" type="button" @click="removePhrase(phrase)"><i
                      class="bi bi-trash"></i></button>
                </div>
              </template>
            </div>
          </div>
          <LinkColors v-if="active_country?.code" :project_id="$route.params.id" :country_code="active_country.code"
                      :setGroup="setGroup"></LinkColors>
        </div>
        <div class="simlinks" v-if="similar">
          <div>
            Показаны похожие на <a :href="links?.find(x=>x.id ===similar)?.link"
                                   target="_blank">{{ links?.find(x => x.id === similar)?.link }}</a>
            <button class="btn btn-sm btn-outline-dark" type="button" @click="order_by='';similar = null; getData();"><i
                class="bi bi-x-lg"></i></button>
          </div>
        </div>
      </form>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr class="sticky-top" style="height: 46px; z-index: 10000;" v-if="check_links.length">
          <th colspan="2">
            <button v-if="check_links.length < 2" class="btn btn-sm btn-outline-dark"
                    @click="similar=check_links[0];findSimilar()">Найти похожие
            </button>
          </th>
          <th>
            <MultiSelect
                v-if="project.project_type !== 'archive' && ($store.getters.permission.includes('link_edit'))"
                v-model="mass_edit.tone"
                :options="Object.keys(this.$TONES).map(x => {return {id: x, name: this.$TONES[x].name}})"
                :multi="false"
                :only_value="true"
                :required="true"
                name="Тональность"></MultiSelect>
          </th>
          <th>
            <MultiSelect
                v-if="project.project_type !== 'archive' && ($store.getters.permission.includes('link_edit')) && mass_edit.tone"
                v-model="mass_edit.type_tone"
                :options="Object.keys($getTypes(mass_edit.tone)).map(x => {return {id: x, name: this.$getTypes(mass_edit.tone)[x]}})"
                :multi="false"
                :only_value="true"
                :required="true"
                name="Тип ссылки"></MultiSelect>
          </th>
          <th colspan="3"></th>
          <th v-if="filter.uniq">
            <AutoComplete
                v-if="!table.filter(x=>check_links.indexOf(x.id)+1).map(x=>x.themes).flat().length"
                v-model="mass_edit.theme"
                :options="themes"
                :multi="true"
                field_name="theme"
                name="Тема"></AutoComplete>
          </th>
          <th scope="col" v-if="project.project_type !== 'audit'"></th>
          <th scope="col" v-if="project.project_type !== 'audit'"></th>
          <th scope="col" v-if="project.project_type !== 'audit'"></th>
          <th scope="col" v-if="filter.uniq"></th>
        </tr>
        <tr class="sticky-top" :style="`top: ${check_links.length?'45':'0'}px`">
          <th style="display: flex; gap: 3px; align-items: baseline;padding: 0 10px;">
            <input id="check_all" type="checkbox" name="check_links"
                   :checked="check_links.length === table.length"
                   @change="check_links=check_links.length === table.length?[]:table.map(x=>x.id);">
            <label for="check_all">№</label></th>
          <th>Ссылка</th>
          <th>Тональность <i class="bi bi-filter" v-on:click="changeOrder('tone_name', $event);"></i></th>
          <th scope="col">Тип ссылки<i class="bi bi-filter" v-on:click="changeOrder('kind_type_name', $event);"></i>
          </th>
          <th>Домен</th>
          <th v-if="filter.uniq">Тип источника</th>
          <th>Запрос <b :class="`ms-2 me-2 screen-links${filter.screen?' text-success':''}`"
                        @click="filter.screen = !filter.screen;getData();" v-if="audit_data?.screen_links">(Выбрано
            ссылок:
            <template v-for="(value, engine) in audit_data?.screen_links[active_country.code]">
              <i :class="`ico ${engine} active`"></i> {{ value.length }}
            </template>
            )</b></th>
          <th scope="col">Вид<i class="bi bi-filter" v-on:click="changeOrder('type_tone_name', $event);"></i></th>
          <th v-if="filter.uniq">Тема</th>
          <th v-if="filter.uniq">Группа</th>
          <th scope="col">SEO-аудит</th>
          <th scope="col" v-if="project.project_type !== 'audit'">Метод воздействия<i class="bi bi-filter"
                                                                                      v-on:click="changeOrder('delete_type', $event);"></i>
          </th>
          <th scope="col" v-if="project.project_type !== 'audit'">Статус<i class="bi bi-filter"
                                                                           v-on:click="changeOrder('status', $event);"></i>
          </th>
          <th scope="col" v-if="project.project_type !== 'audit'">Суд<i class="bi bi-filter"
                                                                        v-on:click="changeOrder('court', $event);"></i>
          </th>
          <!--          <th v-if="this.$store.getters.user.link_price_visible" scope="col">Стоимость</th>-->
          <th scope="col" v-if="filter.uniq">Даты</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(link, index) in table">
          <tr>
            <td style="display: flex; gap: 3px; align-items: baseline;">
              <input :id="link.link_obj_id ? link.link_obj_id : link.id" type="checkbox" name="check_links"
                     :value="link.link_obj_id ? link.link_obj_id : link.id" v-model="check_links">
              <label :for="link.link_obj_id ? link.link_obj_id : link.id">{{ index + 1 + (page - 1) * size }}</label>

            </td>
            <td>
              <div class="d-flex link">
                <div class="control">
                  <router-link class="text-secondary"
                               :to="`/projects/${$route.params.id}/links/${link.link_obj_id ? link.link_obj_id : link.id}/history/`"
                               title="История изменений">
                    <i class="bi bi-clock-history"></i></router-link>
                  <SimLinks v-if="link.tone === 'positive'" :link_id="link.id" :link_obj="link" :open="link.open_bind"
                            :updFunc="updateLink"></SimLinks>
                </div>
                <div>
                  <a :href="link.link" target="_blank" :style="$func.getClass(link)">{{ link.link }}</a>
                  <br>
                  <popper hover>
                    <b style="border-bottom: 1px dotted;cursor: pointer;" class="mt-1"
                       v-if="Object.keys(link.negative_phrase).length">Найдено фраз:
                      {{
                        Object.entries(link.negative_phrase).filter(x => phrases.map(x => x.id).indexOf(parseInt(x[0])) + 1).map(x => x[1]).reduce((s, a) => s + a, 0)
                      }}
                    </b>
                    <template #content>
                     <span class="popper text-start">
                        <p v-for="(val, key) in link.negative_phrase">
                          <template v-if="phrases.find(x => x.id == key)">
                          {{ phrases.find(x => x.id == key)?.text }}: {{ val }}
                            </template>
                        </p>
                    </span>
                    </template>
                  </popper>

                </div>
              </div>
            </td>
            <td>
              <ul class="dropdown list">
                <template
                    v-if="project.project_type !== 'archive' && ($store.getters.permission.indexOf('link_edit') + 1)">
                  <template v-for="(tone, key) in tones(link)">
                    <li v-if="!tone.no_filter"
                        v-on:click="link.get_tone_display=tone.name;
                           link.kind_type='manual';
                          link.get_kind_type_display='Ручной';
                          link.type_tone='new';
                          link.get_type_tone_display='Новый';
                          link.tone=key;
                          updateLink(link, ['kind_type', 'type_tone', 'tone']);"
                        :class="`dropdown-item ${(tone.synonym.indexOf(link.tone)+1)?`active bg-${key}`:''}`">
                      {{ tone.name }}
                    </li>
                  </template>
                </template>
                <template v-else>
                  <li :class="`dropdown-item active bg-${link.tone}`">
                    {{ link.get_tone_display }}
                  </li>
                </template>
              </ul>

            </td>
            <td>
              <ul class="dropdown list">
                <template
                    v-if="project.project_type !== 'archive' && ($store.getters.permission.indexOf('link_edit') + 1)">
                  <li v-for="(tone, key) in  type_tones(link)"
                      v-on:click="updateTypeTone(link, tone, key)"
                      :class="`dropdown-item pb-0 pt-0 ${link.type_tone === key?'active':''}`">{{ tone }}
                  </li>
                </template>
                <template v-else>
                  <li class="dropdown-item active">
                    {{ link.get_type_tone_display }}
                  </li>
                </template>
              </ul>
            </td>
            <td>
              <div class="domain">
                <div>{{ link.domain }}</div>
                <a v-if="link.has_contents" href="" class="btn btn-sm btn-outline-secondary"
                   v-on:click.prevent="getContent(link.link_obj_id ? link.link_obj_id : link.id, link)">
                  Контент
                </a>
                <router-link
                    :to="`/links_statistics/${link.link_obj_id ? link.link_obj_id : link.id}/`"
                    class="btn btn-sm btn-outline-secondary" target="_blank">
                  Динамика ссылки
                </router-link>
                <!--                <router-link class="btn btn-sm btn-outline-secondary"-->
                <!--                             :to="{ name: 'donors_link', params: {id:$route.params.id, link_id: link.link_obj_id ? link.link_obj_id : link.id }}">-->
                <!--                  Доноры-->
                <!--                </router-link>-->
              </div>
            </td>
            <td v-if="filter.uniq">
              <span
                  v-if="link.resource"
                  :class="`no-wrap ${$func.getTrustClass(link.resource)}`"
              >
                <MultiSelect v-model="link.resource.trust"
                             :options="trust"
                             :multi="false"
                             :only_value="true"
                             :addValue="()=>{updateTrust(link.resource);}"
                             name=""></MultiSelect>
              </span>
            </td>
            <td>
              <table class="keys">
                <tr>
                  <td class="key-name"></td>
                  <td v-for="engine in $ENGINES" class="engine">
                    <i @click="setSearchLink(link, engine)"
                       :class="`ico ${engine} ${link[`keys_${engine}`]?.some(v => audit_data?.screen_links[active_country.code]?.[engine]?.includes(v))?'active':''}`"></i>
                  </td>
                </tr>
                <tr class="key" v-for="keyword in link.keyword">
                  <td class="key-name">{{ keyword.name }}</td>
                  <td class="engine">
                    <div class="place" v-for="place in keyword.place.filter(x=>x.engine==='yandex')"> {{
                        place.place
                      }}
                    </div>
                  </td>
                  <td class="engine">
                    <div class="place" v-for="place in keyword.place.filter(x=>x.engine==='google')">
                      {{ place.place }}
                    </div>
                  </td>
                </tr>
              </table>
            </td>
            <td>
              {{ link.get_kind_type_display ? link.get_kind_type_display : '-' }}
            </td>
            <td v-if="filter.uniq">
              <template v-if="($store.getters.permission.indexOf('link_edit') + 1)">
                <form @submit.prevent="updateTheme(link);" class="d-flex theme">
                  <input type="text" class="form-control" v-model="link.theme" maxlength="50">
                  <button class="btn btn-sm btn-dark" type="submit"><i
                      class="bi bi-check-circle-fill"></i></button>
                </form>
                <ul class="dropdown theme list mt-1">
                  <template v-for="theme in themes">
                    <li
                        :class="`dropdown-item text-ellipsis ${(link.themes.indexOf(theme.id)+1)?'active':''}`">
                      <div v-on:click="updateTheme(link, theme)" class="name">{{ theme.theme }}</div>
                      <button @click="del_theme=theme; $modal('show', 'del_theme');" class="btn btn-sm btn-danger"><i
                          class="bi bi-x"></i></button>
                    </li>
                  </template>
                </ul>

              </template>

            </td>
            <td v-if="filter.uniq" class="link-group">
              <template v-if="($store.getters.permission.indexOf('link_edit') + 1)">
                 <select class="form-control" :key="link.id" @change="addGroup(link, $event)">
                   <option value=""></option>
                   <template v-for="group in groups">
                   <option :selected="link.groups.filter(x=>x.id===group.id).length" :style="{background: `rgb(${group.color_rgb})`}" :value="group.id">{{group.name}}</option>
                     </template>
                 </select>

              </template>

            </td>
            <td>
              <div class="checkbox mb-2">
                <input v-model="link.name_block" @change="updateLink(link, ['name_block'])"
                       type="checkbox" value="" :id="'name_block_'+link.id">
                <label :for="'name_block_'+link.id">
                  Блок "Имена"
                </label>
              </div>
              <MultiSelect
                  v-model="link.site_type"
                  :options="$SITE_TYPE"
                  :multi="false"
                  :only_value="true"
                  :addValue="(val)=>{link.site_type=val;updateLink(link, ['site_type']);}"
                  name="Тип страницы"></MultiSelect>
            </td>
            <td v-if="project.project_type !== 'audit'">
              <MultiSelect v-if="$DELETE_TYPES.length"
                           v-model="link.delete_type"
                           :options="$DELETE_TYPES"
                           :multi="false"
                           :only_value="true"
                           :addValue="(val)=>{link.delete_type=val;updateLink(link, ['delete_type']);}"
                           name=""></MultiSelect>

            </td>
            <td v-if="project.project_type !== 'audit'">
              <MultiSelect
                  v-if="project.project_type !== 'archive' && ($store.getters.permission.indexOf('link_edit') + 1)"
                  v-model="link.status"
                  :options="$STATUSES"
                  :multi="false"
                  :only_value="true"
                  :addValue="(val)=>{link.status=val;updateLink(link, ['status']);}"
                  name=""></MultiSelect>
              <span v-else>{{ link.get_status_display ? link.get_status_display : '' }}</span>
            </td>
            <td v-if="project.project_type !== 'audit'">
              <MultiSelect
                  v-if="project.project_type !== 'archive' && ($store.getters.permission.indexOf('link_edit') + 1)"
                  v-model="link.court"
                  :options="$COURT_TYPE"
                  :multi="false"
                  :only_value="true"
                  :addValue="(val)=>{link.court = val;
                            link.delete_type = val === 'yes'?'':link.delete_type;
                            link.get_delete_type_display = val === 'yes'?'':link.get_delete_type_display;
                            updateLink(link, ['court', 'delete_type']);}"
                  name=""></MultiSelect>
              <span v-else>{{ link.get_court_display }}</span>

            </td>
            <td v-if="filter.uniq">
              <span class="no-wrap">Создания: {{ $func.momentDate(link.date_create, 'DD-MM-YYYY') }}</span>
              <span class="no-wrap"
                    v-if="link.date_publication">Публикации: {{
                  $moment(link.date_publication).format('DD-MM-YYYY')
                }}</span>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <nav>
      <ul class="pagination justify-content-center fz-9" v-if="pager.length">
        <li :class="`page-item${p===page?' active':''}`" v-for="p in pager">
          <a v-if="typeof p === 'number'" class="page-link" v-on:click.prevent="page=p" href="">{{ p }}</a>
          <span class="page-link" v-else>{{ p }}</span>
        </li>
      </ul>
    </nav>
    <modal modal_id="link_content">
      <template v-slot:header v-if="content">
        <div class="blog-post">
          <h2 class="blog-post-title" v-html="content.title"></h2>

          <p class="blog-post-meta">{{ content.date_create }}</p>
        </div>
        <ul style="font-weight: 300; font-size: 11px;">
          <li>
            <b class="text-primary">text</b> - Словоформы имени персоны
          </li>
          <li>
            <span class="bg-warning">text</span> - Запросы
          </li>
          <li>
            <span class="bg-negative">text</span> - Негативные отрезки
          </li>
          <li>
            <span class="bg-danger">text</span> - Негативные фразы
          </li>
        </ul>
      </template>

      <template v-slot:body>
        <div class="card">


          <div class="card-body pb-5" v-if="content">
            <article class="blog-post">


              <p v-html="content.description"></p>
              <hr>
              <div v-html="content.content"></div>
            </article>

          </div>
        </div>
      </template>
      <template v-slot:footer></template>
    </modal>
    <modal modal_id="del_theme">
      <template v-slot:header v-if="del_theme">
        <h2>
          Удалить тему "{{ del_theme.theme }}"?
        </h2>
      </template>

      <template v-slot:body>
        <p>Тема будет отключена от все ссылок и удалена из списков</p>
      </template>
      <template v-slot:footer v-if="del_theme">
        <button class="btn btn-sm btn-secondary" @click="$modal('hide', 'del_theme');">Отмена</button>
        <button class="btn btn-sm btn-danger" @click="removeTheme(del_theme)">Удалить</button>

      </template>
    </modal>
  </main>
</template>

<script>
import {ProjectApi, LinkApi} from '@/api/project'
import {PictureApi} from '@/api/pictures'
import SimLinks from "@/components/SimLinks.vue";
import moment from "moment"
import {AuditApi} from "@/api/audit";
import {PhraseApi} from "@/api/phrase";
import RussianName from "@/plugins/morph"
import {ResourceApi} from "@/api/resource";
import LinkColors from "@/views/links/includes/LinkColors.vue";
import {GroupApi} from "@/api/groups";

export default {
  name: 'LinksInfo',
  components: {LinkColors, SimLinks},

  data() {
    return {
      table: [],
      links: [],
      project: {},
      check_links: [],
      per_pages: [
        {id: 10, name: '10'},
        {id: 20, name: '20'},
        {id: 50, name: '50'},
        {id: 100, name: '100'},
        {id: 200, name: '200'},
        {id: "all", name: 'Все'},
      ],
      filter: {
        tone: [],
        date: null,
        type_tone: [],
        kind_type: [],
        keyword: '',
        delete_type: '',
        court: '',
        theme: '',
        uniq: true,
        state: null,
        screen: false,
        phrase: null,
        trust: null,
      },
      mass_edit: {
        tone: null,
        type_tone: null,
        theme: []
      },
      similar: null,
      active_country: {code: null, yandex: true, google: true},
      trust: [],
      keywords: [],
      percent_similarity: 80,
      all_themes: [],
      person_names: [],
      phrases: [],
      groups: [],
      order_by: '',
      content: null,
      active_link: null,
      page: 1,
      show_all: false,
      size: 50,
      all_count: 0,
      del_theme: null,
      q: '',
      audit_data: null,
      filter_types: [{id: 'auto', name: 'Автоматический'}, {id: 'manual', name: 'Ручной'}],
      allowedDates: []
      // role_with_restrictions: ['wiki_expert', 'analyst']
    }
  },
  computed: {
    themes() {
      return this.all_themes || []
    },
    pager() {
      let page_count = Math.ceil(this.all_count / this.size);
      let pages = Array.from({length: page_count}, (_, i) => i + 1);
      let array = [];
      if (this.page > 5) {
        array = array.concat(pages.slice(0, this.page - 3 > 3 ? 3 : this.page - 3));
        array.push('...')
      }
      array = array.concat(pages.slice(this.page - 4 > 0 ? this.page - 4 : 0, this.page + 3));
      if (this.page < (pages.length - 5)) {
        array.push('...');
        array = array.concat(pages.slice(pages.length - 3, pages.length));
      }
      return array;
    },
    query: function () {
      return this.$route.query
    },
    name: function () {
      let name = `${this.project.name}: `
      let data = this.$route.query;
      if (this.active_country.code) data.country_code = this.active_country.code;
      let query = {...data, ...this.filter}
      name += query.date || moment().format("DD/MM/YYYY")
      return name
    },
    filter_tones: function () {
      let data = Object.keys(this.$TONES).filter(x => !this.$TONES[x].no_filter).map(x => {
        return {id: x, name: this.$TONES[x].name}
      })
      data.push({id: 'error', name: 'Нет контента'})
      data.push({id: 'null', name: 'Не размечена'})
      return data
    },
    filter_type_tones: function () {
      let types_name = {};
      Object.values(this.$TONES).map(x => Object.assign(types_name, x.type_tones));
      return Object.keys(types_name).map(x => {
        return {id: x, name: types_name[x]}
      })
    },
    tones: function () {
      let vm = this;
      return function (link) {
        return vm.$getTones(link.type_tone);
      };
    },
    type_tones: function () {
      let vm = this;
      return function (link) {
        return vm.$getTypes(link.tone);
      };
    },
  },
  watch: {

    active_country(val) {
      this.filter.country_code = val.code;
      this.getKeywords();
    },
    links(new_value) {
      if (new_value) {
        this.table = new_value.slice()
        // let order = this.order_by
        // this.table = this.sorting_table_data(this.table, order)
      }
    },

    order_by(new_value) {
      // this.table = this.sorting_table_data(this.table, new_value)
    },

    page: {
      deep: true,
      handler(val) {
        this.getData();
      }
    },

    mass_edit: {
      deep: true,
      handler(val) {
        let $this = this;
        ProjectApi.mass_update(this.$route.params.id, {links: this.check_links, data: this.mass_edit}).then((resp) => {
          for (let _index in resp) {
            let data = resp[_index];
            let link_index = $this.table.findIndex(x => x.id === data.id);
            $this.table.splice(link_index, 1, data)
          }
        })


      }
    }
  },
  mounted: function () {
    const project_settings = this.$store.getters.user.settings.projects[this.$route.params.id];
    if (project_settings && project_settings.links && !this.$route.query) this.filter = {...this.filter, ...project_settings.links};
    this.filter = {...this.filter, ...this.$route.query}
    ProjectApi.get_avail_date_calendar(this.$route.params.id).then(response => this.allowedDates = response)
    ProjectApi.choices("trust").then(response => this.trust = response)
    if (this.filter.tone) {
      let tone = this.filter.tone
      if (typeof tone === 'string') tone = [tone]
      this.filter.tone = tone;
    }
    if (this.filter.kind_type) {
      let kind_type = this.filter.kind_type
      if (typeof kind_type === 'string') kind_type = [kind_type]
      this.filter.kind_type = kind_type;
    }
    if (this.filter.type_tone) {
      let type_tone = this.filter.type_tone
      if (typeof type_tone === 'string') type_tone = [type_tone]
      this.filter.type_tone = type_tone;
    }

    let $this = this;
    ProjectApi.get_simple(this.$route.params.id).then(resp => {
      $this.project = resp;
      if (!$this.filter.date) {
        $this.filter.date = moment($this.project.last_parser, "DD/MM/YYYY").format("YYYY-MM-DD");
      }
      if ($this.query.country_code) $this.active_country = $this.project.countries.filter(x => x.code === $this.query.country_code)[0];
      else $this.active_country = $this.project.active_country_code;

      this.getData();
      AuditApi.get($this.$route.params.id).then(resp => {
        $this.audit_data = resp
        if (!Object.keys($this.audit_data).length) {
          $this.audit_data = {screen_links: {yandex: [], google: []}}
        }
      })
      $this.getKeywords()
      ProjectApi.get_themes($this.$route.params.id).then(response => {
        $this.all_themes = response;
      })
      PhraseApi.list($this.$route.params.id).then(response => $this.phrases = response);
    })
  },
  methods: {
    addGroup(link, r){
      GroupApi.add_link(this.$route.params.id, {link_id: link.id, id: r.target.value, country_code: this.active_country.code})
    },
    findSimilar() {
      let $this = this;
      LinkApi.findSimilar(this.similar, this.active_country.code).then(response => {
        $this.order_by = "check";
        $this.links = response;
        $this.links.forEach(link => {
          $this.$ENGINES.forEach(engine => {
            if (link.keyword) {
              let keys = Object.keys(link.keyword).map(x => link.keyword[x].place.filter(key => key.engine === engine)).flat().sort((f, s) => f.place - s.place)
              link[`keys_${engine}`] = keys.map(x => x.id)
            }
          })
        })
        $this.all_count = 0;
      })
    },
    setSearchLink(link, engine) {
      let keys = link[`keys_${engine}`];


      if (!keys?.length) this.$notify({
        type: 'error ',
        text: 'Нет доступных позиций в выдаче'
      });
      else {
        const key = keys[0];
        if (Object.values(link.keyword).map(x => x.place).flat().find(x => x.id === key)?.place > 20) {
          this.$notify({
            type: 'error ',
            text: 'Можно выбрать только ссылки из ТОП 20'
          });
        } else {
          if (!this.audit_data.screen_links[this.active_country.code]) {
            this.audit_data.screen_links[this.active_country.code] = {
              yandex: [],
              google: []
            }
          }
          const index = this.audit_data.screen_links[this.active_country.code][engine].indexOf(key);
          if (index < 0) this.audit_data.screen_links[this.active_country.code][engine].push(key)
          else this.audit_data.screen_links[this.active_country.code][engine].splice(index, 1)
          AuditApi.add_link(this.$route.params.id, Object.values(this.audit_data.screen_links).map(x => Object.values(x)).flat(2))
        }
      }


    },
    removeTheme(theme) {
      let $this = this;
      theme.project_id = this.$route.params.id;
      LinkApi.remove_theme_links(theme).then(() => {
        let theme_index = $this.all_themes.findIndex(x => x.id === theme.id)
        $this.all_themes.splice(theme_index, 1)
        $this.links.forEach((e) => {
          theme_index = e.themes.findIndex(x => x === theme.id)
          if (theme_index >= 0) e.themes.splice(theme_index, 1)
        })
        $this.$modal('hide', 'del_theme')
      })
    },
    updateTheme(link, theme) {
      let $this = this, theme_query, link_id = link.link_obj_id || link.id;
      if (theme) theme_query = theme;
      else {
        if (link.theme) {
          theme_query = {
            id: null,
            theme: link.theme,
            country: this.active_country.id
          }
          link.theme = ""
        } else {
          this.$notify({
            type: 'error ',
            text: 'Введите тему'
          });
        }
      }
      if (theme_query) {
        let has_index = link.themes.indexOf(theme_query.id);
        if (has_index + 1) {
          LinkApi.remove_theme(link_id, theme_query).then(() => {
            link.themes.splice(has_index, 1)
          })
        } else {
          LinkApi.add_themes(link_id, theme_query).then((response) => {
            link.themes.push(response.id)
            if (!theme && !$this.all_themes.filter(x => x.id === response.id).length) $this.all_themes.push(response)
          })
        }

      }
    },

    sorting_table_data(data, order_by) {
      if (order_by.indexOf('-') + 1) {
        order_by = order_by.slice(1);
        data.sort((a, b) => {
          return this.compareObjects(b, a, order_by);
        });
      } else if (order_by === "check") {
        return this.table.sort((a, b) => parseInt(b.id) === parseInt(this.similar) ? 1 : -1)
      } else {
        data.sort((a, b) => {
          return this.compareObjects(a, b, order_by);
        });
      }

      return data

    },

    getKeywords() {
      PictureApi.keywords(this.$route.params.id, this.active_country.code).then(x => this.keywords = x)
    },

    getContent: function (id, link) {
      let $this = this;
      $this.active_link = link;
      let keywords = Object.values(this.keywords).map(x => x.name)
      LinkApi.get_content(id).then(response => {
        let content = response;
        $this.person_names = [];
        if (content.person_name) {
          let name = new RussianName(content.person_name);
          name.cases.forEach((_case) => {
            $this.person_names = $this.person_names.concat(name.fullName(_case).split(" "))
          });
        }
        let text = "", prev = 0, last_index = content.content.length + 1;
        content.negative_index.forEach((elem) => {
          let start = elem[0], end = elem[1];
          if ((start - prev) >= 1) text += content.content.substring(prev, start)
          text += `<span class="bg-negative">${content.content.substring(start, end)}</span>`
          prev = end;
        })
        if (prev < last_index) text += content.content.substring(prev, last_index);
        text = text.split('\n')
        text = text.map(x => `<p>${x}</p>`)
        content.content = text.join("");

        $this.person_names.forEach(key => {
          if (content.content) content.content = content.content.replaceAll(new RegExp(`(${key})`, 'ig'), `<b class="text-primary">$1</b>`)
          if (content.description) content.description = content.description.replaceAll(new RegExp(`(${key})`, 'ig'), `<b class="text-primary">$1</b>`)
          if (content.title) content.title = content.title.replaceAll(new RegExp(`(${key})`, 'ig'), `<b class="text-primary">$1</b>`)
        })
        keywords.forEach(key => {
          if (content.content) content.content = content.content.replaceAll(new RegExp(`(${key})`, 'ig'), `<span class="bg-warning">$1</span>`)
          if (content.description) content.description = content.description.replaceAll(new RegExp(`(${key})`, 'ig'), `<span class="bg-warning">$1</span>`)
          if (content.title) content.title = content.title.replaceAll(new RegExp(`(${key})`, 'ig'), `<span class="bg-warning">$1</span>`)
        })
        this.phrases.forEach(key => {
          if (content.content) content.content = content.content.replaceAll(new RegExp(`(${key.text})`, 'ig'), `<span class="bg-danger">$1</span>`)
          if (content.description) content.description = content.description.replaceAll(new RegExp(`(${key.text})`, 'ig'), `<span class="bg-danger">$1</span>`)
          if (content.title) content.title = content.title.replaceAll(new RegExp(`(${key.text})`, 'ig'), `<span class="bg-danger">$1</span>`)
        })

        $this.content = content;
        $this.$modal('show', 'link_content');
      })
    },
    getData: function () {
      let $this = this, data = this.$route.query;
      if (this.active_country.code) data.country_code = this.active_country.code;
      let query = {...data, ...JSON.parse(JSON.stringify(this.filter))}
      query.page = $this.page;
      query.size = $this.size;
      if (query.trust === 0) query.trust = String(query.trust) // Do not delete this line!! one of the options has a value of 0, and is excluded from the filter
      query.project = $this.$route.params.id;
      query.link = $this.q.replace("https://", "").replace("http://", "").replace("www.", "");
      query = Object.fromEntries(Object.entries(query).filter(([_, v]) => Array.isArray(v) ? v.length : !!v))
      let user_data = {projects: {}}
      user_data.projects[this.$route.params.id] = {links: query}
      this.$store.dispatch("setSettings", user_data)
      history.pushState(JSON.stringify(query), null, location.pathname + `?${$this.$func.createSearchParams(query)}`);
      // location.search = $this.$func.createSearchParams().toString();
      if (query.tone && query.tone.indexOf("error") + 1) {
        query.tone.splice(query.tone.indexOf("error"), 1)
        query.error = true
      }
      const method = query.uniq ? LinkApi.list : LinkApi.searches
      method($this.$func.createSearchParams(query).toString()).then(response => {
        $this.links = response.results;
        $this.links.forEach(link => {
          $this.$ENGINES.forEach(engine => {
            if (link.keyword) {
              let keys = Object.keys(link.keyword).map(x => link.keyword[x].place.filter(key => key.engine === engine)).flat().sort((f, s) => f.place - s.place)
              link[`keys_${engine}`] = keys.map(x => x.id)
            }
          })
        })
        $this.all_count = response.count;
      })
    },
    compareObjects: function (object1, object2, key) {
      if (key) {
        const obj1 = object1[key] != null ? typeof object1[key] == 'string' ? object1[key].toUpperCase() : object1[key] : '';
        const obj2 = object2[key] != null ? typeof object2[key] == 'string' ? object2[key].toUpperCase() : object2[key] : '';
        if (obj1 < obj2) {
          return -1
        }
        if (obj1 > obj2) {
          return 1
        }
        return 0
      } else {
        if ((object1.date_create > object2.date_create) && (Object.keys(object1.keyword).length > 0)) {
          return -1
        }
        return 1

      }
    },
    changeOrder: function (name) {
      if (this.order_by === name) {
        if (name.indexOf('-') + 1) this.order_by = name.slice(1);
        else this.order_by = '-' + name
      } else this.order_by = name

    },
    updateTypeTone(link, name, key) {
      if (key === 'our_derivative') {
        link.open_bind = true;
      } else {
        link.get_type_tone_display = name;
        link.type_tone = key;
        this.updateLink(link, ['type_tone'])
      }
    },
    updateLink: function (link, update_field = [], update = false) {
      let $this = this, id = link.link_obj_id ? link.link_obj_id : link.id, data = {};
      if (update_field.length) {
        update_field.forEach(field => data[field] = link[field])
      } else data = JSON.parse(JSON.stringify(link))

      let link_index = $this.links.findIndex(x => x.id === link.id);
      data.id = id
      LinkApi.update(data).then(response => {
        if (update && link_index >= 0) $this.links.splice(link_index, 1, response)
        this.$notify({
          type: 'success ',
          text: 'Данные обновлены'
        });
      })
    },
    addPhrase(phrase) {
      let $this = this;
      if (phrase.text.length) PhraseApi.add_phases(this.$route.params.id, phrase).then(response => {
        let index = $this.phrases.indexOf(phrase)
        $this.phrases[index] = response
      });
    },
    removePhrase(phrase) {
      if (phrase.id) PhraseApi.remove_phases(this.$route.params.id, phrase);
      this.phrases.splice(this.phrases.indexOf(phrase), 1)
    },
    searchPhrase() {
      PhraseApi.start_search(this.$route.params.id);
    },
    updateTrust(resource) {
      let $this = this;
      ResourceApi.patch(resource.id, {trust: resource.trust}).then(() => {
        $this.$notify({
          type: 'success ',
          text: 'Данные обновлены'
        });
        $this.links.forEach(function (link) {
          if (link.resource?.id && link.resource?.id === resource.id) {
            link.resource.trust = resource.trust;
          }
        })
      })
    },
    setGroup(groups) {
      this.groups = groups
    }
  },
}

</script>
<style scoped lang="scss">
.link-group{
  select{
    width: 150px;
  }
}
.dropdown {
  &.theme {
    li {
      justify-content: space-between;
      padding: 0 0 0 10px;

      .name {
        width: 100%;
      }

      button {
        color: #ffffff;
        padding: 0 5px;
        line-height: 10px;
        font-weight: bold;
        height: 100%;
      }
    }
  }
}

.control {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}

.phrases {
  div {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  button {
    padding: 0 5px;
  }

  .list {
    align-items: start;
    flex-direction: column;

    &.hide {
      display: none;
    }
  }
}

.screen-links {
  display: inline-flex;
  gap: 5px;
  align-items: center;
  border-bottom: 1px dotted;
  cursor: pointer;

  &:hover {
    border-color: #ccc;
  }
}

.key-name {
  width: 300px;
}

.per-page {
  display: flex;
  align-items: baseline;
  gap: 10px;

  .select {
    width: 100px;
  }
}


</style>